import { ReactNode, useEffect, useState } from 'react'
import { AppProviderInterface } from '@shared/interfaces/app-provider.interface'
import { AppContext } from '@providers/app-provider/_contexts'
import SquareLoader from '@ui/loader/square'
import { AnimatePresence } from 'framer-motion'

export interface Props {
  categoryQuery?: string
  children: ReactNode
  currentPage?: string
  word?: string
  refererPage?: string
}

export const AppProvider = ({ children, word, currentPage, categoryQuery, refererPage }: Props) => {
  // States
  const [searchQuery, setSearchQuery] = useState<string>(word ?? '')
  const [category, setCategory] = useState<string>(categoryQuery ?? '')
  const [currentPath, setCurrentPath] = useState<string>(currentPage ?? '/')
  // We aren't using the referer at the moment, but I think is good idea to keep it.
  const [referer, setReferer] = useState<string>(refererPage ?? '/')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Handlers
  const handleOnSearch = (query: string) => setSearchQuery(query)
  const handleCategory = (category: string) => setCategory(category)
  const handleReferer = (referer: string) => setReferer(referer)
  const handleCurrentPath = (path: string) => setCurrentPath(path)
  const toggleIsLoading = (loading: boolean) => setIsLoading(loading)

  // Context
  const appContext: AppProviderInterface = {
    searchQuery,
    category,
    currentPath,
    referer,
    isLoading,
    handleOnSearch,
    handleCurrentPath,
    toggleIsLoading,
    handleCategory,
    handleReferer,
  }

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}
