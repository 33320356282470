import dynamic from 'next/dynamic'
import { APIDETAILS } from '@apis/_utilities/environment/api.url.details'
import { HtmlHead } from '@library/head-html/html-head'
import {
  EXPLORE_META_DESCRIPTION,
  EXPLORE_META_IMAGE,
  EXPLORE_META_IMAGE_ALT,
  EXPLORE_META_KEYWORDS,
  EXPLORE_META_TITLE,
} from '@shared/constants/root.constants'
import { AppProvider } from '@providers/app-provider/app-context'

const HomePageComponent = dynamic(() => import('@features/home-page'), { ssr: false })
const PagesLayout = dynamic(() => import('@components/layout/pages-layout'), { ssr: false })
const Layout = dynamic(() => import('@components/layout'), { ssr: false })

export default function Index() {
  return (
    <>
      <HtmlHead
        url={`${APIDETAILS.webDomain}`}
        title={EXPLORE_META_TITLE}
        description={EXPLORE_META_DESCRIPTION}
        imagePathname={EXPLORE_META_IMAGE}
        imageAlt={EXPLORE_META_IMAGE_ALT}
        keywords={EXPLORE_META_KEYWORDS}
      />
      <Layout>
        <AppProvider>
          <PagesLayout>
            <HomePageComponent />
          </PagesLayout>
        </AppProvider>
      </Layout>
    </>
  )
}
